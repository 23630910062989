import React from 'react';
import Layout from '../components/layout';
import Questions from '../components/Questions';

const PlastischeChirurgie = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Plastische Chirurgie"
			noSlider
			{...props}
		>
			<p>
				Gerne vermitteln wir Ihnen für jede gewünschte ästehtische
				Veränderung einen darauf spezialisierten plastischen Chirurgen.
			</p>
			<Questions title="plastische Chirurgie" />
		</Layout>
	);
};

export default PlastischeChirurgie;
